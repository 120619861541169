/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';
import config from "../config"
import { HypersignEdvClientEcdsaSecp256k1 } from 'hypersign-edv-client'

Vue.use(Vuex)
// @dev
// We expect devs to convert their DIDDoc from ldjson to json
function ldToJsonConvertor(ld) {
    const json = {};
    for (const key in ld) {
        if (key === "@context") {
            json['context'] = ld[key];
            // } else if (ld[key] === "" || (Array.isArray(ld[key]) && ld[key].length === 0)) {
            //     json[key] = undefined;
        } else {
            json[key] = ld[key]
        }
    }
    return json;
}

export default new Vuex.Store({
    state: {
        entityAccessToken: "",
        did: "",
        didDocument: {},
        credentials: [],
        edvClient: null,
        isAnyUnsavedOnEdv:false,
        stateWalletAddress:"",
        credentialIdArr: [],
        edvData:{}
    },

    getters: {
        getDidDocVm: (state) => {
            return Object.assign({}, state.didDocument.verificationMethod[0])
        },
        getEntityHeader: (state) => {
            return {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.entityAccessToken
            }
        },
        getUnChangedDIDDOcJSON: (state) => {
            return ldToJsonConvertor(state.didDocument)
        }
        ,
        getDIDDocJSON: (state) => {
            return ldToJsonConvertor(JSON.parse(JSON.stringify(ldToJsonConvertor(state.didDocument), (key, value) => {
                if (value === "" || (Array.isArray(value) && value.length === 0)) {
                    return undefined;
                }
                return value;
            })))
        },
        getUnsavedStatusForEdv: (state) => {
            let flag = state.credentials.some((x) => x.credInEdv !== true)
            if (flag === undefined) {
                flag = false
            }
            return flag
        },

        // @entityDev 
        // TODO:  this has to be figured out. A developer would not know how to use this
        getDIDDocJSONString: (state) => {
            return JSON.stringify(ldToJsonConvertor(state.didDocument), (key, value) => {
                if (value === "" || (Array.isArray(value) && value.length === 0)) {
                    return undefined;
                }
                return value;
            })
        },
    },
    mutations: {
        setEntityAccessToken(state, payload) {
            state.entityAccessToken = payload
        },
        setWalletAddress(state,payload){            
            if(payload!==""){
                state.stateWalletAddress = payload
            }            
        },
        setDID(state, payload) {
            state.did = payload
        },
        setDIDDocument(state, payload) {
            state.didDocument = payload
        },

        // @entityDev 
        // TODO:  this has to be figured out. A developer would not know how to use this
        addVerificationMethod(state, payload) {
            const { blockchainId } = payload

            const t = state.didDocument.verificationMethod.find(x => x.blockchainAccountId === blockchainId)
            if (t) {
                throw new Error('Wallet Address already added in the didDoc, choose different one')
            }

            const controller = state.did
            const vmId = `did:hid:testnet:${blockchainId.split(':')[2]}#key-${state.didDocument.verificationMethod.length + 1}`

            state.didDocument.verificationMethod.push({
                id: vmId,
                type: payload.publicKey ? "EcdsaSecp256k1VerificationKey2019" : "EcdsaSecp256k1RecoveryMethod2020",
                controller: controller,
                publicKeyMultibase: payload.publicKey ? payload.publicKey : "",
                blockchainAccountId: blockchainId
            })
            state.didDocument.authentication.push(vmId)
            state.didDocument.assertionMethod.push(vmId)
        },

        removeVerificationMethod(state,payload){
            if(payload===""){
                return
            }
            const controller = state.did
            const payload_raw= payload.split('#')[0]
            if(payload_raw==controller){
               throw  new Error('Cannot remove the Controller')
            }
            const vmId = payload
            const vms = state.didDocument.verificationMethod.filter(x => x.id !== vmId)
            const auths = state.didDocument.authentication.filter(x => x !== vmId)
            const assertions = state.didDocument.assertionMethod.filter(x => x !== vmId)
            state.didDocument.verificationMethod = vms
            state.didDocument.authentication = auths
            state.didDocument.assertionMethod = assertions




        },
        addCredentialsToStore(state, payload) {
            state.credentials.push(payload)
        },
        setEdvSaveStatus(state, payload) {
            state.isAnyUnsavedOnEdv = payload
        },
        addCredentialsToStoreIndexSorted(state, payload) {
            Vue.set(state.credentials, payload.index, payload.credential)

        },
        addToIndex(state, payload) {
            state.credentialIdArr.push(payload)
        

        },
        cleanStates: (state) => {
            state.credentialIdArr = []
            state.did = ""
            state.didDocument = {}
            state.credentials = []
            state.edvClient = null
            state.stateWalletAddress = ""
            state.edvData = {}
        }


    },
    actions: {

        addCredentialsToStoreIndexSorted: ({ commit }, payload) => {
           commit( 'addToIndex',payload.credential.credential.credentialDocument.id)

            commit('addCredentialsToStoreIndexSorted', payload)
        },
        authenticateSSIAppAction: ({ commit }) => {
            return new Promise((resolve, reject) => {
                const url = config.apiBaseURL + '/api/v1/app/oauth'

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Api-Secret-Key': config.apiSecret
                    }
                }).then(resp => {
                    return resp.json()
                }).then(json => {
                    if (json.statusCode == 400) {
                        throw new Error('Bad Request ' + json.message.toString())
                    }
                    if (json.statusCode == 401) {
                        throw new Error('Invalid API Secret Key')
                    }
                    const { access_token } = json;
                    commit('setEntityAccessToken', access_token)
                    resolve()
                }).catch(e => {
                    reject(e.message)
                })
            })
        },
        createADID: ({ commit, getters }, payload) => {
            return new Promise((resolve, reject) => {
                const url = config.apiBaseURL + '/api/v1/did/create'
                const body = {
                    namespace: 'testnet',
                    options: {
                        keyType: payload.keplr ? 'EcdsaSecp256k1VerificationKey2019' : 'EcdsaSecp256k1RecoveryMethod2020',
                        walletAddress: payload.walletAddress,
                        chainId: payload.chainId,
                        publicKey: payload.publicKey ? payload.publicKey : undefined
                    }
                }
                fetch(url, {
                    method: 'POST',
                    headers: getters.getEntityHeader,
                    body: JSON.stringify(body)
                }).then(resp => {
                    return resp.json()
                }).then(json => {
                    if (json.statusCode == 400) {
                        throw new Error('Bad Request ' + json.message.toString())
                    }
                    if (json.statusCode == 401) {
                        if (json.name.includes("TokenExpiredError")) {
                            commit("authenticateSSIAppAction")
                            commit("createADID", payload)
                        }
                        throw new Error('Unauthenticated')
                    }

                    const { did, metaData } = json;
                    const { didDocument } = metaData;
                    if (!didDocument.verificationMethod[0].type.includes('EcdsaSecp256k1VerificationKey2019')) {
                        didDocument.verificationMethod[0].publicKeyMultibase = ""
                        didDocument.service = []
                    }
                    commit('setDIDDocument', didDocument);
                    commit('setDID', did);
                    resolve(json)
                }).catch(e => {
                    reject(e.message)
                })
            })
        },
        registerADID: ({ state, commit, getters }, payload) => {
            return new Promise((resolve, reject) => {
                const url = config.apiBaseURL + '/api/v1/did/register'
                if (!state.didDocument || !state.did) {
                    return reject('No did found, create a new did')
                }
                let body;
                if (payload.address) {
                    body = {
                        didDocument: getters.getUnChangedDIDDOcJSON,
                        signInfos: [{
                            verification_method_id: payload.verificationMethodId,
                            clientSpec: { type: "cosmos-ADR036", adr036SignerAddress: payload.address },
                            signature: payload.signature.signature,

                        }]
                    }
                } else {
                    body = {
                        didDocument: getters.getDIDDocJSON,
                        signInfos: [{
                            verification_method_id: payload.verificationMethodId,
                            clientSpec: { type: "eth-personalSign" },
                            signature: payload.signature,
                        }]
                    }
                }

                fetch(url, {
                    method: 'POST',
                    headers: getters.getEntityHeader,
                    body: JSON.stringify(body)
                }).then(resp => {
                    return resp.json()
                }).then(json => {
                    if (json.statusCode == 400) {
                        throw new Error('Bad Request ' + json.message.toString())
                    }
                    if (json.statusCode == 401) {
                        if (json.name.includes("TokenExpiredError")) {
                            commit("authenticateSSIAppAction")
                            commit("registerADID", payload)
                        }
                        throw new Error('Unauthenticated')
                    }
                    resolve(json)
                }).catch(e => {
                    reject(e.message)
                })
            })
        },
        updateADID: ({ commit, getters }, payload) => {
            return new Promise((resolve, reject) => {
                const url = config.apiBaseURL + '/api/v1/did'
                const body = {
                    didDocument: getters.getDIDDocJSON,
                    signInfos: payload
                }

                fetch(url, {
                    method: 'PATCH',
                    headers: getters.getEntityHeader,
                    body: JSON.stringify(body)
                }).then(resp => {
                    return resp.json()
                }).then(json => {
                    if (json.statusCode == 400) {
                        throw new Error('Bad Request ' + json.message.toString())
                    }
                    if (json.statusCode == 401) {
                        if (json.name.includes("TokenExpiredError")) {
                            commit("authenticateSSIAppAction")
                            commit("updateADID", payload)
                        }
                        throw new Error('Unauthenticated')
                    }

                    const { transactionHash } = json;

                    if (!transactionHash) {
                        throw new Error('Could not update DID')
                    }
                    resolve(json)
                }).catch(e => {
                    reject(e.message)
                })
            })
        },
        resolveADID: ({ commit, getters }, payload) => {
            return new Promise((resolve, reject) => {
                const url = config.apiBaseURL + '/api/v1/did/resolve/' + payload.didId
                fetch(url, {
                    method: 'GET',
                    headers: getters.getEntityHeader,
                }).then(resp => {
                    return resp.json()
                }).then(json => {
                    if (json.statusCode == 400) {
                        throw new Error('Bad Request ' + json.message.toString())
                    }
                    if (json.statusCode == 401) {
                        if (json.name.includes("TokenExpiredError")) {
                            commit("authenticateSSIAppAction")
                            commit("resolveADID", payload)
                        }
                        throw new Error('Unauthenticated')
                    }
                    if (json.statusCode == 404) {
                        return resolve(undefined);
                    }

                    const { didDocument } = json;
                    if (Object.keys(didDocument).length === 0) {
                        return resolve(undefined);
                    }

                    resolve(didDocument)
                }).catch(e => {
                    reject(e.message)
                })
            })
        },
        issueCredential: ({ state, getters, commit }, payload) => {
            return new Promise((resolve, reject) => {
                const url = config.apiBaseURL + '/api/v1/credential/issue'
                if (!state.didDocument || !state.did) {
                    return reject('No did found, create a new did')
                }


                const schemaMap = {
                    "discord": "sch:hid:testnet:z4RzEpiYEiJArpJDo9ay74db8E3fQW2Ppau67b2v6JMX5:1.0",
                    "twitter": "sch:hid:testnet:zAu5hb37aJ4n7S4GLkE9TSmxqBrVQ9TpMwks63UjKv5un:1.0",
                    "github": "sch:hid:testnet:z46BvRLYDUNTKr5NT726aitngPCemkmQSE728DTU4YTWW:1.0",
                }
                const body = {
                    schemaId: schemaMap[payload.sub.includes("discord") ? "discord" : payload.sub.includes("twitter") ? "twitter" : "github"],
                    subjectDid: state.did,
                    issuerDid: "did:hid:testnet:z6ary7M7yNFPMStGXwYByUoaLoCTp8D1Ux6sW7ByxxkFm",
                    expirationDate: "2027-12-10T18:30:00.000Z",
                    fields: {
                        ...payload
                    },
                    namespace: "testnet",
                    verificationMethodId: "did:hid:testnet:z6ary7M7yNFPMStGXwYByUoaLoCTp8D1Ux6sW7ByxxkFm#key-1",
                    persist: true
                }


                fetch(url, {
                    method: 'POST',
                    headers: getters.getEntityHeader,
                    body: JSON.stringify(body)
                }).then(resp => {
                    return resp.json()
                }).then(json => {
                    if (json.statusCode == 400) {
                        throw new Error('Bad Request ' + json.message.toString())
                    }
                    if (json.statusCode == 401) {
                        if (json.name.includes("TokenExpiredError")) {
                            commit("authenticateSSIAppAction")
                            commit("issueCredential", payload)
                            throw new Error('Unauthenticated')
                        }
                    }
                    if (json.statusCode == 404) {
                        throw new Error('Not Found')
                    }
                    const credential = { encrypted: false, credInEdv: false, credential: json }
                    commit('addCredentialsToStore', credential)
                    commit('setEdvSaveStatus', true)
                    resolve(json)
                }).catch(e => {
                    reject(e.message)
                })
            })
        },


        // TODO: Implement this With EDV
        storeCreatedCredential: ({ state, getters, commit }, payload) => {
            // initialize the edv client
            return new Promise((resolve, reject) => {
                try {
                    state.edvClient.insertDoc({
                        edvId: `hs:edv:${state.didDocument.id}`,
                        documentId: `hs:credentials:${payload.credential.credentialDocument.id}`,
                        document: payload.credential,
                        metadata: {
                            schemaId: payload.credential.credentialDocument.credentialSchema.id,
                            credentialId: payload.credential.credentialDocument.id

                        }
                    }).then((data) => {
                        resolve(data)
                    }).catch(e => {
                        reject(e)
                    })


                }
                catch (e) {
                    reject(e)
                }

            })




        },

        connectEdv: ({ state, getters, commit }, payload) => {
            return new Promise((resolve, reject) => {
                try {
                    const verificationMethod = getters.getDidDocVm
                    verificationMethod.id = verificationMethod.id.split('#')[0] + '#' + verificationMethod.blockchainAccountId
                    const edvClient = new HypersignEdvClientEcdsaSecp256k1({
                        url: config.edv.BASE_URL,
                        verificationMethod
                    })
                    edvClient.registerEdv({
                        edvId: `hs:edv:${state.didDocument.id}`,
                        verificationMethod,

                    })
                        .then((data) => {
                            state.edvData = data
                            state.edvClient = edvClient

                            resolve(data)
                        })

                }
                catch (e) {
                    reject(e)
                }
            })
        },
        fetchCredentials: ({ state, getters, commit }, payload) => {

            return new Promise((resolve, reject) => {
                try {

                    state.edvClient.fetchAllDocs({
                        edvId: `hs:edv:${state.didDocument.id}`,
                    }).then((data) => {
                        resolve(data)
                    }).catch(e => {
                        reject(e)
                    })
                }


                catch (e) {
                    reject(e)
                }
            })



        },

        updateCredential: ({ state, getters, commit }, payload) => {
            return new Promise((resolve, reject) => {
                try {
                    state.edvClient.updateDoc({
                        document: state.credentials,
                        edvId: `hs:edv:${state.didDocument.id}`,
                        documentId: `hs:credentials:${state.didDocument.id}`,
                    }).then((data) => {
                        resolve(data)
                    }
                    ).catch(e => {
                        reject(e)
                    })

                }
                catch (e) {
                    reject(e)
                }
            })

        }
        ,
        decryptCredential: ({ state, getters, commit }, payload) => {

            return new Promise((resolve, reject) => {
                try {
                    state.edvClient.decryptDocument(payload)
                        .then((data) => {
                            resolve(data)
                        }
                        ).catch(e => {
                            reject(e)
                        }
                        )

                } catch (error) {
                    reject(error)
                }

            })

        },

        setCredential: ({ state, getters, commit }, payload) => {
            state.credentials.push(payload.credential)
        },

    }

})
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('NavBar',{attrs:{"title":_vm.name,"show":_vm.showUserNav,"themeData":_vm.themeData,"isForm":_vm.isForm}}),_c('div',{class:[
    _vm.isSidebarCollapsed 
        ? 'container-collapsed-not hideNavbar'
        : 'container-collapsed',
  ]},[(_vm.showNavbar)?_c('sidebar-menu',{staticClass:"sidebar-wrapper",attrs:{"collapsed":_vm.isSidebarCollapsed,"theme":'white-theme',"width":"220px","menu":_vm.isSubscribed? _vm.menu : _vm.unsubsSubscribedMenu},on:{"toggle-collapse":_vm.onToggleCollapse,"item-click":_vm.onItemClick}},[_c('div',{staticStyle:{"background":"#363740"},attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"ml-1 mt-3 mb-2",staticStyle:{"padding-left":"1px","text-align":"center","margin-right":"2.25rem !important"}},[(!_vm.isSidebarCollapsed)?_c('a',{attrs:{"href":"/admin/dashboard"}},[_c('img',{attrs:{"src":require('./assets/Fyre_Small.png'),"alt":"logo","width":"130vw"}})]):_vm._e(),(_vm.isSidebarCollapsed)?_c('a',{attrs:{"href":"/admin/dashboard"}},[_c('img',{attrs:{"src":require('./assets/favicon.png'),"alt":"logo","width":"35vw"}})]):_vm._e()]),_c('hr',{staticClass:"rule"})]),_c('span',{staticClass:"text-center",staticStyle:{"font-size":"14px","padding":"7px","border":"1px solid #80808014"},attrs:{"slot":"footer"},slot:"footer"},[_c('a',{staticStyle:{"text-decoration":"none","background-color":"transparent","color":"#8B8B8B"},attrs:{"href":"https://docs.fyre.hypersign.id/","target":"_blank"}},[_vm._v("Docs")])]),_c('span',{staticClass:"text-center",staticStyle:{"font-size":"12px","padding":"7px"},attrs:{"slot":"footer"},slot:"footer"},[_vm._v(_vm._s(_vm.$config.app.version))])]):_vm._e(),_c('div',{class:[
      _vm.showNavbar
          ? 'content-wrapper'
          : 'content-wrapper-not',
    ]},[_c('router-view')],1),_vm._m(0)],1),_c('notifications',{attrs:{"group":"foo"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_vm._v("Powered By: "),_c('a',{attrs:{"href":"https://hypersign.id/","target":"_blank"}},[_vm._v(" HyperSign")])])
}]

export { render, staticRenderFns }